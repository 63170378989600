import React from 'react';
import Layout from '@components/layout';
import Seo from '@components/seo';
import { THEME_WHITE } from '@/src/common/consts/theme';
import Segment from '@components/segment';
import FormFysioMassan from '@components/FormFysioMassan';

const FysioMassanPage = () => (
  <Layout
    dataTheme={THEME_WHITE}
    headerWitdh="header"
    headerStyle="hide--all"
    lang="se"
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />

    <Seo title="Anmäl ditt intresse" language="sv" />

    <section data-theme={THEME_WHITE}>
      <div className="flex flex--center">
        <a href="https://www.jointacademy.com/se/sv/">
          <img
            className="logo--md flex"
            src="https://assets.jointacademy.com/logos/jointacademy_logo_pos.svg"
            alt=""
          />
        </a>
      </div>
      <div className="container container--xs flex flex--column flex--center text--center margin-bottom--lg margin-top--md">
        <div className="salesforce-sms-page" style={{ overflow: 'visible' }}>

          <div className="salesforce-sms-page__text-container" style={{ maxWidth: 500 }}>
            <h2 className="text--lg margin-bottom--xs">Anmäl ditt intresse</h2>
            <p className="">
              Lämna dina uppgifter nedan om du vill veta mer om hur det är att jobba hos Joint Academy
            </p>
          </div>

          <div style={{ marginTop: 20, maxWidth: 470 }}>
            <FormFysioMassan />
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default FysioMassanPage;

import React from 'react';

const CheckboxImage = ({ checked, size = 40 }) => (checked
  ? (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#5096E8" />
      <path d="M30.0312 12.9688C30.6406 13.5312 30.6406 14.5156 30.0312 15.0781L18.0312 27.0781C17.4688 27.6875 16.4844 27.6875 15.9219 27.0781L9.92188 21.0781C9.3125 20.5156 9.3125 19.5312 9.92188 18.9688C10.4844 18.3594 11.4688 18.3594 12.0312 18.9688L17 23.8906L27.9219 12.9688C28.4844 12.3594 29.4688 12.3594 30.0312 12.9688Z" fill="white" />
    </svg>
  )
  : (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="38" height="38" rx="7" fill="white" />
      <rect x="1" y="1" width="38" height="38" rx="7" stroke="#D4DBE2" strokeWidth="2" />
    </svg>
  ));

export default CheckboxImage;

import React, { useRef, useState } from 'react';
import CheckboxImage from '@components/atoms/CheckboxImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const FormFysioMassan = () => {
  const formRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const checkboxRef = useRef(null);
  const professionRef = useRef(null);
  const submitRef = useRef(null);

  const [researchCheckboxChecked, setResearchCheckboxChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const setDisableFields = (disabled = true) => {
    emailRef.current.disabled = disabled;
    firstNameRef.current.disabled = disabled;
    lastNameRef.current.disabled = disabled;
    checkboxRef.current.disabled = disabled;
    professionRef.current.disabled = disabled;
    submitRef.current.disabled = disabled;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const params = {
      source: 'fysiomassan',
      email: emailRef.current.value,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      profession: professionRef.current.value || 'Annat',
      researchSubscription: checkboxRef.current.checked,
    };

    window.analytics.identify(params);

    submitRef.current.value = 'Skickar';

    setDisableFields();
    setTimeout(() => {
      setDisableFields(false);
      setResearchCheckboxChecked(false);
      event.target.reset();
      submitRef.current.value = 'Anmäl ditt intresse';

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3500);
    }, 400);
  };

  const handleFormReset = (event) => {
    event.preventDefault();
    setResearchCheckboxChecked(false);
    formRef?.current?.reset();
  };

  return (
    <form onSubmit={onSubmit} className="form margin-top--xxs" ref={formRef}>
      <div>
        <div className="grid grid--gap-sm text--left">
          <div className="col--md-6">
            <input
              ref={firstNameRef}
              id="first_name"
              name="first_name"
              type="text"
              autoCapitalize="off"
              autoCorrect="off"
              defaultValue=""
              placeholder="Förnamn"
              className="form-control form-control--md text--left"
              required
            />
          </div>

          <div className="col--md-6">
            <input
              ref={lastNameRef}
              id="last_name"
              name="last_name"
              type="text"
              autoCapitalize="off"
              autoCorrect="off"
              defaultValue=""
              placeholder="Efternamn"
              className="form-control form-control--md text--left"
              required
            />
          </div>

          <div className="col--md-12">
            <input
              ref={emailRef}
              id="email"
              name="email"
              type="email"
              autoCapitalize="off"
              autoCorrect="off"
              className="margin-right--xs form-control form-control--md text--left"
              placeholder="E-postadress"
              required
            />
          </div>

          <div className="col--md-12">
            <div className="form-select-wrapper">
              <select ref={professionRef} className="form-control" id="industry" name="industry">
                <option value="">Yrke</option>
                <option value="Leg. Sjukgymnast/Fysioterapeut">Leg. Sjukgymnast/Fysioterapeut</option>
                <option value="Leg. Arbetsterapeut">Leg. Arbetsterapeut</option>
                <option value="Annat">Annat</option>
              </select>

              <FontAwesomeIcon icon={faChevronDown} className="form-select-wrapper__icon" />
            </div>
          </div>

          <div className="col--md-12">
            <div className="checkbox-container flex flex--center margin-top--xs margin-bottom--xs">
              <input
                type="checkbox"
                id="reseachInterest"
                name="reseachInterest"
                ref={checkboxRef}
                onChange={(event) => setResearchCheckboxChecked(event.target.checked)}
              />
              <label htmlFor="reseachInterest">
                <CheckboxImage checked={researchCheckboxChecked} size={28} />
                <span>Jag vill veta mer om Joint Academys forskning</span>
              </label>
            </div>
          </div>

          <div className="col--md-12 flex flex--center">
            <input
              ref={submitRef}
              type="submit"
              value="Anmäl ditt intresse"
              name="submit"
              className="btn btn--primary btn--md btn--fullwidth-sm"
            />
          </div>

          {showSuccess && (
            <div className="col--md-12 flex flex--center text--center">
              Tack för visat intresse!
              <br />
              Vi kommer kontakta dig via Mail inom kort.
            </div>
          )}

          <div className="col--md-12 flex flex--center">
            <a
              href={undefined}
              className="display--xs"
              onClick={handleFormReset}
              style={{ textDecoration: 'underline', color: '#7f94a9', marginTop: 10 }}
            >
              Rensa formulär
            </a>
            <a
              href="https://www.jointacademy.com/se/sv/"
              className="hide--xs"
              style={{ textDecoration: 'underline', color: '#7f94a9', marginTop: 10 }}
            >
              Joint Academy
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormFysioMassan;
